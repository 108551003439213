import './styles/search.scss';

import React from 'react';
import ReactDOM from 'react-dom';

import SearchContainer from './containers/SearchContainer';

document.addEventListener('DOMContentLoaded', () => {
  const node = document.getElementsByClassName('nav-link search')[0];

  if (node !== undefined) ReactDOM.render(<SearchContainer />, node);
});
