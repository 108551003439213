import axios from 'axios';
import $ from 'jquery';
import React, { Component } from 'react';

import I18n from '../../core/i18n';
import ToolTip from '../../shared/input_elements/tool_tip/ToolTip';
import Tracker from '../../utils/tracker';
import { handleCsrfError } from '../../utils/urlHelper';
import SuggestionItem from '../components/SearchSuggestionItem';
import fetchChallengeQuery from '../queries/fetchChallengeQuery';
import fetchSuggestionsQuery from '../queries/fetchSuggestionsQuery';

const searchIcon = require('../../images/icons/search.png');

const ENTER_KEY = 13;
const ESC_KEY = 27;

const CHARS_TO_TRIGGER_SEARCH = 3;

const SEARCH_RESULTS = 3;

if (document.querySelector('meta[name="csrf-token"]')) {
  handleCsrfError();
}

class SearchContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchInput: '',
      showSearchBar: false,
      showSuggestions: false,
      slicedFilteredList: [],
      viewer: {},
    };
  }

  componentDidMount() {}

  handleEnableSearchBar = () => {
    $('.c-progress-bar').toggle();

    this.setState(
      {
        showSearchBar: true,
      },
      () => {
        this.searchBar.focus();
      }
    );
  };

  handleClose = () => {
    $('.c-progress-bar').toggle();

    this.setState({
      showSearchBar: false,
    });
  };

  handleSearchChange = (value) => {
    if (value.length >= CHARS_TO_TRIGGER_SEARCH) {
      this.setState(
        {
          searchInput: value,
        },
        () => {
          this.fetchSuggestionsData();
        }
      );
    } else {
      this.setState({
        searchInput: value,
        showSuggestions: false,
      });
    }
  };

  fetchSuggestionsData = () => {
    const { searchInput } = this.state;

    const query = fetchSuggestionsQuery({ search: searchInput });
    let suggestionsList;
    axios
      .post('/graphql', { query })
      .then((response) => {
        const { data } = response.data;
        if (data) {
          suggestionsList = data.searchTakeaways;
          this.setState(
            {
              showSuggestions: true,
              suggestionsList,
              viewer: data.viewer,
            },
            () => {
              this.filterSuggestionsList();
            }
          );
        }
      })
      .catch((error) => {
        console.log('error: ', error);
      });
  };

  handleKeyDown = (e) => {
    const { viewer } = this.state;

    const enterKeyEnabled =
      window.location.pathname.split('/')[1] !== 'challenge_questions';

    if (e.keyCode === ESC_KEY) {
      $('.c-progress-bar').show();

      this.setState({
        searchInput: '',
        showSearchBar: false,
        showSuggestions: false,
      });
    } else if (e.keyCode === ENTER_KEY && enterKeyEnabled) {
      const { searchInput } = this.state;

      if (searchInput) {
        Tracker.track({
          event_data: {
            source: 'searchbar',
            term: searchInput,
            user_id: viewer.id,
          },
          event_type: 'learning/search/searched',
        });
        window.location.href = `/suggestions?search=${encodeURIComponent(
          searchInput
        )}`; // go to the search results page
      }
    }
  };

  handleSuggestionClick = (suggestionId) => {
    const { searchInput, viewer } = this.state;
    const query = fetchChallengeQuery({
      takeawayId: suggestionId,
    });

    Tracker.track({
      event_data: {
        source: 'searchbar',
        term: searchInput,
        user_id: viewer.id,
      },
      event_type: 'learning/search/searched',
    });

    if (suggestionId === 'all') {
      Tracker.track({
        event_data: {
          reference: suggestionId,
          source: 'searchbar',
          term: searchInput,
          user_id: viewer.id,
        },
        event_type: 'learning/search/results/viewed',
      });

      // setTimeout(() => {
      //   window.location.href = `/challenges/${challengeId}`;
      // }, 1000);

      window.location.href = `/suggestions?search=${encodeURIComponent(
        searchInput
      )}`; // go to the search results page
    } else {
      let challengeId;
      axios
        .post('/graphql', { query })
        .then((response) => {
          const { data } = response.data;
          if (data.getChallenge) {
            challengeId = data.getChallenge.id;

            Tracker.track({
              event_data: {
                reference: suggestionId,
                source: 'searchbar',
                term: searchInput,
                user_id: viewer.id,
              },
              event_type: 'learning/search/challenge/viewed',
            });

            setTimeout(() => {
              window.location.href = `/challenges/${challengeId}`;
            }, 1000);
          } else {
            Tracker.track({
              event_data: {
                reference: suggestionId,
                source: 'searchbar',
                term: searchInput,
                user_id: viewer.id,
              },
              event_type: 'learning/search/discovery/viewed',
            });

            setTimeout(() => {
              window.location.href = `/challenges/discovery/${suggestionId}`;
            }, 1000);
          }
        })
        .catch((error) => {
          console.log('error: ', error);
        });
    }
  };

  filterSuggestionsList = () => {
    const { suggestionsList, searchInput } = this.state;
    let filteredList = suggestionsList.filter(
      (item) => item.title.search(new RegExp(searchInput, 'i')) > 0
    );
    if (filteredList.length === 0) {
      filteredList = filteredList.filter(
        (item) => item.content.search(new RegExp(searchInput, 'i')) > 0
      );
    }
    let slicedFilteredList = filteredList;
    if (filteredList.length > 0) {
      slicedFilteredList = filteredList.slice(0, SEARCH_RESULTS);
      slicedFilteredList.push({
        content: '',
        id: 'all',
        title: I18n.t('search.bar.all_results'),
      });
    }

    this.setState({
      slicedFilteredList,
    });
  };

  render() {
    const { searchInput, showSearchBar, showSuggestions, slicedFilteredList } =
      this.state;

    return (
      <div className="search-container">
        <div className="search-container__input">
          {showSearchBar ? (
            <ToolTip position="bottom" text={I18n.t('search.bar.tooltip')}>
              <input
                ref={(inputEl) => (this.searchBar = inputEl)}
                className="search__input"
                placeholder={I18n.t('search.bar.placeholder')}
                type="text"
                value={searchInput}
                onChange={(e) => this.handleSearchChange(e.target.value)}
                onKeyDown={(e) => this.handleKeyDown(e)}
              />
              <span
                className="search__close"
                role="button"
                onClick={this.handleClose}
              >
                X
              </span>
            </ToolTip>
          ) : (
            <img
              alt=""
              className="search__icon"
              src={searchIcon}
              onClick={this.handleEnableSearchBar}
            />
          )}
        </div>

        {showSearchBar && showSuggestions && (
          <ul className="search-container__suggestions">
            {slicedFilteredList.map((each) => (
              <SuggestionItem
                key={each.id}
                handleSuggestionClick={this.handleSuggestionClick}
                search={searchInput}
                suggestion={each.title}
                suggestionId={each.id}
              />
            ))}
          </ul>
        )}
      </div>
    );
  }
}

export default SearchContainer;
