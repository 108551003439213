import './ToolTip.scss';

import PropTypes from 'prop-types';
import React, { useState } from 'react';

const ToolTip = ({ children, text, position }) => {
  const [showToolTip, setToolTip] = useState(false);

  const hideToolTip = () => {
    setToolTip(false);
  };

  const displayToolTip = () => {
    setToolTip(true);
  };

  const hasText = text !== "";

  return (
    <>
    {hasText ? (
      <span className="tooltip__container" onMouseLeave={hideToolTip}>
        {showToolTip && (
          <div className={`tooltip__bubble tooltip--${position}`}>
            <div className="tooltip__message">{text}</div>
          </div>
        )}
        <span
          className="tooltip__trigger"
          onFocus={displayToolTip}
          onMouseOver={displayToolTip}
        >
          {children}
        </span>
      </span>
      ) : (
        <>
          {children}
        </>
      )}
    </>
  );
};

ToolTip.propTypes = {
  children: PropTypes.node.isRequired,
  position: PropTypes.string,
  text: PropTypes.string,
};

ToolTip.defaultProps = {
  position: 'right',
  text: 'Something',
};

export default ToolTip;
