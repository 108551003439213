export const highlightText = (string, text, className) => {
  // Option: all occurrences but doesn't match original case
  // const regex = new RegExp(search,'ig');
  // const suggestionDisplayed = suggestion.replace(regex, `<span class="suggestions-search">${search}</span>`);

  // Option: first occurrence
  const index = string.toLocaleLowerCase().indexOf(text.toLocaleLowerCase());
  let stringHighlighted = string;
  if (index !== -1) {
    stringHighlighted = `${string.slice(
      0,
      index
    )}<span class="${className}">${string.slice(
      index,
      index + text.length
    )}</span>${string.slice(index + text.length)}`;
  }

  // Option: multiple occurrences NOT WORKING
  /*
  let index = string.toLocaleLowerCase().indexOf(text.toLocaleLowerCase());
  let stringHighlighted = string;
  let oldIndex = 0;
  while (index !== -1) {

    stringHighlighted = string.slice(oldIndex, index) + `<span class="${className}">${string.slice(index, index + text.length)}</span>` + string.slice(index + text.length);
    oldIndex = index;
    index = string.toLocaleLowerCase().indexOf(text.toLocaleLowerCase(), oldIndex + 1);
  }
  */

  return stringHighlighted;
};
