import axios from 'axios';

export default function Tracker() {}

Tracker.track = function (event) {
  axios({
    data: {
      event,
    },
    method: 'post',
    url: '/events',
  });
};

Tracker.trackPublic = function (event) {
  axios({
    method: 'post',
    url: '/events/public',
    data: {
      event,
    },
  });
};
