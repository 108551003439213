const fetchSuggestionsQuery = ({ search }) => `
query {
  searchTakeaways(search: "${search}") {
    id
    content
    title
  }
  viewer {
    id
  }
}`;

export default fetchSuggestionsQuery;
