import axios from 'axios';

export const removeQueryStringParameter = (key, url) => {
  let urlClean = !url ? window.location.href : url;
  const hashparts = urlClean.split('#');
  const regex = new RegExp(`([?&])${key}=.*?(&|#|$)`, 'i');

  if (hashparts[0].match(regex)) {
    urlClean = hashparts[0].replace(regex, '$1');
    urlClean = urlClean.replace(/([?&])$/, '');

    if (typeof hashparts[1] !== 'undefined' && hashparts[1] !== null)
      urlClean += `#${hashparts[1]}`;
  }
  return urlClean;
};

export const handleCsrfError = () => {
  axios.defaults.headers.common = {
    'X-CSRF-TOKEN': document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute('content'),
    'X-Requested-With': 'XMLHttpRequest',
  };
};

export const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};
