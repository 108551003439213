import PropTypes from 'prop-types';
import React from 'react';

import Markdown from '../../shared/utilities/markdown/Markdown';
import { highlightText } from '../../suggestions/utils/helper';

export default function SearchSuggestionItem({
  suggestion,
  handleSuggestionClick,
  search,
  suggestionId,
}) {
  const suggestionHighlighted = highlightText(
    suggestion,
    search,
    'search-suggestion-search'
  );

  return (
    <li
      className="search-suggestion-item"
      onClick={() => handleSuggestionClick(suggestionId)}
    >
      <div className="search-suggestion-name">
        <Markdown>{suggestionHighlighted}</Markdown>
      </div>
    </li>
  );
}

SearchSuggestionItem.propTypes = {
  handleSuggestionClick: PropTypes.func.isRequired,
  search: PropTypes.string,
  suggestion: PropTypes.string,
  suggestionId: PropTypes.string.isRequired,
};

SearchSuggestionItem.defaultProps = {
  search: '',
  suggestion: '',
};
